import { Tooltip } from 'react-tooltip'
import { getTranslation } from '../utils/languageUtils'
import { gtmDataLayerPush } from '../utils/googleTagManager/utils'
import { useCloseableModal } from './modals/hooks/useCloseableModal'
import { useHandleClickOutside } from '../utils/hooks/useHandleClickOutside'
import Button from './Button'
import React, { useEffect, useRef } from 'react'

const TooltipButton = ({ href, text }: any) => (
  <div>
    <a
      style={{ cursor: 'pointer' }}
      className='saps-button saps-button-grey-6--outline saps-mt-5 saps-mb-10'
      target='_blank'
      href={href}
      rel='noreferrer'
    >
      {text}
    </a>
  </div>
)

const TooltipBody = (props: Props & { closeTooltip: () => void }) => {
  const [showVin, setShowVin] = React.useState(false)

  const allowedToClose = useRef(false)
  const closeTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (!allowedToClose.current) {
      setTimeout(() => (allowedToClose.current = true), 1000)
    }
  })

  const handleSetShowVin = () => {
    setShowVin(true)
  }

  return (
    <div
      style={{ textAlign: 'center' }}
      onMouseOut={() => {
        if (allowedToClose.current) {
          closeTimeout.current = setTimeout(() => props.closeTooltip(), 500)
        }
      }}
      onMouseOver={() => {
        if (closeTimeout.current) {
          clearTimeout(closeTimeout.current)
        }
      }}
    >
      <div className='saps-pt-10 saps-pb-10'>
        {showVin ? (
          <>
            <b>{getTranslation('cert.tooltip.vin')}</b> {props.vin ?? ''}
          </>
        ) : (
          <button
            className='saps-button saps-button-grey-6--outline saps-mt-5 saps-mb-10'
            onClick={handleSetShowVin}
          >
            {getTranslation('cert.tooltip.showVin')}
          </button>
        )}
      </div>
      <div>
        {props.cebiaCertUrl && (
          <TooltipButton
            href={`/images/${props.cebiaCertUrl}`}
            text={getTranslation('cert.tooltip.cebia.download')}
          />
        )}
        {props.skodaPlus && (
          <TooltipButton
            href={`/public/cert/skodaplus?carId=${props.id}`}
            text={getTranslation('cert.tooltip.skoda1.download')}
          />
        )}
        {props.oneYearCar && (
          <TooltipButton
            href={`/public/cert/oneyear?carId=${props.id}`}
            text={getTranslation('cert.tooltip.skoda2.download')}
          />
        )}
        {!!props.smartCode && props.smartCode.smartCodeUrl && (
          <Button
            fullWidth
            style={{ marginTop: '1rem' }}
            greyButton
            onClick={() =>
              !!props?.smartCode?.handleShowCebiaModal
                ? props.smartCode.handleShowCebiaModal()
                : null
            }
          >
            {getTranslation('carDetail.cebiaButton')}
          </Button>
        )}
      </div>
    </div>
  )
}

type Props = {
  vin?: string
  cebiaCertUrl?: string | null
  skodaPlus?: boolean | null
  id?: string
  oneYearCar?: boolean | null
  smartCode?: { smartCodeUrl: string; handleShowCebiaModal: () => void } | null
  smartCodeUrl?: string | null
}
export const CertTooltip = (props: Props) => {
  const [showTooltip, setShowTooltip] = React.useState(false)

  const handleShowTooltip = () => {
    gtmDataLayerPush({
      event: 'trackEvent',
      eventCategory: 'Microsite - skodaplus',
      eventAction: 'Click',
      eventLabel: 'Cebia certificate',
      'appweb.Name': 'ms_click_certificate_vin',
    })
    setShowTooltip(true)
  }
  return (
    <>
      <a
        className='saps-car-detail-cebia__text-link my-anchor-element'
        data-tip
        data-event='click'
        onClick={handleShowTooltip}
      >
        {props.cebiaCertUrl === null
          ? getTranslation('cert.tooltip.vin')
          : `${getTranslation('cert.tooltip.certificate')} / ` + getTranslation('cert.tooltip.vin')}
      </a>
      <Tooltip
        anchorSelect='.my-anchor-element'
        place='bottom'
        clickable
        isOpen={showTooltip}
        style={{ zIndex: 10 }}
      >
        <TooltipBody {...props} closeTooltip={() => setShowTooltip(false)} />
      </Tooltip>
    </>
  )
}
